const PUBLIC = {
  404: {
    PATH: '/404',
    VIEW: 'NotFound',
  },
  HOME: {
    PATH: '/',
    VIEW: 'Home',
  },
  ABOUT: {
    PATH: '/about',
    VIEW: 'About',
  },
  BLOG: {
    PATH: '/blog',
    VIEW: 'ArticleList/Blog',
  },
  AUTHORS: {
    PATH: '/authors',
    VIEW: 'AuthorList',
  },
  ARTICLES: {
    PATH: '/articles',
    VIEW: 'ArticleList/Articles',
  },
  PRIVACY: {
    PATH: '/privacy',
    VIEW: 'Policy',
  },
  TERMS: {
    PATH: '/terms',
    VIEW: 'Policy',
  },
  OFFERS: {
    PATH: '/terms/offers',
    VIEW: 'Policy',
  },
  COOKIES: {
    PATH: '/cookies',
    VIEW: 'Policy',
  },
  SUPPORTED_BROWSERS: {
    PATH: '/supported-browsers',
    VIEW: 'SupportedBrowsers',
  },
  CONTACT: {
    PATH: '/contact',
    VIEW: 'Contact',
  },
  CAREERS: {
    PATH: '/careers',
    VIEW: 'Careers',
  },
  /** Start JOB ROLES */
  WILL_WRITER: {
    PATH: '/careers/will-writer',
    VIEW: 'Careers/Roles/WillWriter',
  },
  ACCOUNT_MANAGER: {
    PATH: '/careers/account-manager',
    VIEW: 'Careers/Roles/AccountManager',
  },
  CUSTOMER_EXPERIENCE_SPECIALIST: {
    PATH: '/careers/customer-experience-specialist',
    VIEW: 'Careers/Roles/CustomerExperienceSpecialist',
  },
  /** END JOB ROLES */
  WHAT_TO_DO_WHEN: {
    PATH: '/what-to-do-when-someone-dies',
    VIEW: 'WhatToDoWhenSomeoneDies',
  },
  CHARITIES: {
    PATH: '/work-with-us-charities',
    VIEW: 'Charities',
  },
  PRESS: {
    PATH: '/press',
    VIEW: 'Press',
  },
  SECURITY: {
    PATH: '/security',
    VIEW: 'Security',
  },
  SECURITY_REPORTING: {
    PATH: '/security-reporting',
    VIEW: 'SecurityReporting',
  },
  JOHN_BOOTH: {
    PATH: '/urns',
    VIEW: 'JohnBooth',
  },
  CONTENT_HUB: {
    PATH: '/guides',
    VIEW: 'ContentHub',
  },
  CONTENT_HUB_SEARCH: {
    PATH: '/guides/search',
    VIEW: 'ContentHub/SearchPage',
  },
}

const PPC = {}

export const ROUTES = { PUBLIC, PPC }
