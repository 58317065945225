import { v4 as uuid } from 'uuid'

import { FAREWILL_COOKIE_DOMAIN } from 'config'
import { getCookie, setCookie } from 'lib/storage/cookies/helpers'
import { CookieNames } from 'lib/storage/cookies/constants'

export const isBrowser = (): boolean => typeof window !== 'undefined'

/**
 * Fetches a unique id which can be used to identify this users browser/browsing
 * session. This is passed to the Split.io SDK so that the user is served
 * consistent treatments for any feature flags, meaning they won't see different
 * treatments across page loads/browsing sessions where browser storage is
 * retained.
 *
 * @returns A UUID.
 */
export const getClientId = (): string => {
  const existingKey = getCookie(CookieNames.CLIENT_ID)
  if (existingKey) {
    return existingKey
  }

  const clientId = uuid()
  setCookie(CookieNames.CLIENT_ID, clientId, {
    domain: FAREWILL_COOKIE_DOMAIN as string,
    expires: 90,
  })
  return clientId
}

/**
 * DNT: do not track
 * This is a (deprecated) API that allows users to express a preference to not be tracked.
 * It can usually be controlled via browser preferences in supported browsers.
 * See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
 * @returns boolean
 */
export const hasDNTEnabled = (): boolean => window.navigator.doNotTrack === '1'
