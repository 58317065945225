import React from 'react'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'

import { getUserConsent } from '../../src/lib/split/helpers'
import { getClientId } from '../../src/lib/browser/helpers'
import { ENABLE_SPLIT_DEBUG_MODE } from '../../src/config'
import { AppWrapper } from './AppWrapper'
import { GtmImpressionListener } from '../../src/lib/split/gtm-impression-listener'

import { useSplitEventTracking } from './useSplitEventTracking'
import type { GatsbySplitIOPluginOptions } from './types'

export const wrapRootElement = (
  { element }: { element: React.ReactElement },
  pluginOptions: GatsbySplitIOPluginOptions
): React.ReactElement => {
  if (!pluginOptions.sdk?.authorizationKey) {
    console.error(
      `Missing authorizationKey for Split.io. Please add an authorizationKey in the plugin options in gatsby-config`
    )
  }

  return (
    <SplitFactoryProvider
      config={{
        core: { ...pluginOptions.sdk, key: getClientId() },
        // Only provide the `debug` key if ENABLE_SPLIT_DEBUG_MODE is true as
        // the config option here takes precedence over the localStorage flag
        // (which can be useful to enable debug mode in production for your
        // browser).
        ...(ENABLE_SPLIT_DEBUG_MODE ? { debug: true } : null),
        userConsent: getUserConsent(),
        scheduler: {
          // Push metrics events every 10 seconds rather than the default 60.
          // Note: The Split SDK also listens for `pagehide` events and flushes
          // metric events to Split servers when these occur so as not to drop
          // events fired just before the user navigates away. However, the
          // SDK does not appear to listen to the beforeunload event
          // (see https://web.dev/bfcache/#never-use-the-unload-event), so an
          // event fired just before the user closes the tab could still be
          // dropped.
          eventsPushRate: 10,
        },
        impressionListener: new GtmImpressionListener(),
      }}
    >
      <AppWrapper element={element} />
    </SplitFactoryProvider>
  )
}

const SplitWrapper = ({ children }: { children: React.ReactElement }) => {
  useSplitEventTracking()

  return <>{children}</>
}

export const wrapPageElement = ({
  element,
}: {
  element: React.ReactElement
}): React.ReactElement => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <SplitWrapper>{element}</SplitWrapper>
)
